const path = require(`path`)
const pageTemplate = path.resolve("./src/templates/single/page.js")
const homeTemplate = path.resolve("./src/templates/page/home.js")
const contactTemplate = path.resolve("./src/templates/page/contact.js")

const { pageData, homeData, postData } = require("../src/preview/previewData")

// Page Templates
const templateMap = {
  default: {
    // Default should be our default internal
    template: pageTemplate,
    previewData: pageData,
  },
  home: {
    template: homeTemplate,
    previewData: homeData,
  },
  contact: {
    template: contactTemplate,
  },

}

// Post Types
const postTypeMap = {
  post: {
    previewData: postData,
  },
}

module.exports = { templateMap, postTypeMap }
