const pageData = `
query($id: ID!) {
  page(id:  $id ) {
    title
    content
    uri
    headerSettings {
      customPageTitle
      hdDescription
    }
    seo {
      title
      metaDesc
    }

    avForm {
      fmHeading
      fmDescription
      showForm
    }
    ancestors {
      nodes {
        ... on Page {
          depth
          title
          uri
        }
      }
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    pageSettings {
      relatedPages {
        ... on Page {
          title
          excerpt
          uri
          featuredImage {
            node {
              sourceUrl
              srcSet
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
  }
}
`

const homeData = `
query($id: ID!) {
  page(id:  $id ) {
    title
    headerSettings {
      customPageTitle
      hdDescription
    }
    seo {
      title
      metaDesc
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }

    homePageSections {
      featuredItems {
        title
        excerpt
        link {
          ... on Page {
            uri
          }
        }
        linkText
      }
      ctaBlock {
        title
        excerpt
        pageLink {
          ... on Page {
            uri
          }
        }
        linkText
      }
      logos {
        heading
        images {
          image {
            sourceUrl
            srcSet
          }
          link
        }
      }
      contactBlock {
        title
        excerpt
        pageLink {
          ... on Page {
            uri
          }
        }
        linkText
      }
    }
  }
}
`
const postData = `
query($id: ID!) {
  post(id:  $id ) {
    title
    content
    featuredImage {
      node {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    categories {
      nodes {
        name
        slug
      }
    }
  }
}
`

module.exports = {
  pageData,
  postData,
  homeData,
}
