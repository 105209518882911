import React, { useState, useEffect } from "react"

export const PreviewContext = React.createContext()

const PreviewProvider = ({ children }) => {
  const [previewData, setPreviewData] = useState(null)

  const contextValues = {
    previewData,
    setPreviewData,
  }
  // useEffect(() => {
  //   console.log("previewData:", previewData)
  // }, [previewData])

  return (
    <PreviewContext.Provider value={contextValues}>
      {children}
    </PreviewContext.Provider>
  )
}

export default PreviewProvider
